import React, {useEffect, useState, useContext} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import hexToRgba from "hex-to-rgba"
import { ThemeContext } from "styled-components";

import api from "../../../services/api"

import {UseStyles} from "./styles"
  

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  table: {
    // backgroundColor: props => props.background.secondary
  },
  tableContainer: {
    boxShadow: "0 0 0 0 rgba(255, 105, 135, 0)",
    // borderRadius: 0,
    // borderBottomLeftRadius: 0,
    // borderBottomRightRadius: 0,
    // background: "transparent",
    // borderTopColor: props => hexToRgba(props.primary, "0.4"),
    borderTopWidth: 0.4
  },
  tableCell: {
    // borderBottomColor: props => props.tableBorder,
    fontSize: "0.775rem",
    // color: props => props.text
  },
  tableLastRow: {
    "&:last-child th, &:last-child td": {
      borderBottomColor: "transparent"
    }
  },
  tableHeaderCell: {
    borderTopColor:  hexToRgba("#00b000", "0.4"),
    borderTopWidth: 1,
    borderBottomColor:  hexToRgba("#00b000", "0.4"),
    backgroundColor:  hexToRgba("#00b000", "0.02"),
    // color:  props.primary,
    fontWeight: "normal"
  }
});

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  // const classes = useRowStyles();
  const { colors } = useContext(ThemeContext);
  const classes = UseStyles(colors);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.calories}</TableCell>
        <TableCell align="right">{row.fat}</TableCell>
        <TableCell align="right">{row.carbs}</TableCell>
        <TableCell align="right">{row.protein}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} className={classes.collapse} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Carmelo
              </Typography>
              <Table className={classes.table} size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableHeaderCell}>Equipamento</TableCell>
                    <TableCell className={classes.tableHeaderCell}>Capacidade</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="right">Semana Anterior</TableCell>
                    <TableCell className={classes.tableHeaderCell} align="right">Semana Corrente</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.history.map((historyRow) => (
                    <TableRow key={historyRow.date} className={classes.tableLastRow}>
                      <TableCell component="th" scope="row">
                        {historyRow.date}
                      </TableCell>
                      <TableCell>{historyRow.customerId}</TableCell>
                      <TableCell align="right">{historyRow.amount}</TableCell>
                      <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

// const rows = [
//   createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
//   createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
//   createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
//   createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
//   createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5),
// ];

export default function InstrumentCapacity() {
  const classes = useRowStyles();
  const [rows, setRows] = useState([])

  useEffect(() => {
    async function loadInstrumentCapacity(){
      const response = await api.get("weeklyreports/instrument")
      console.log(response?.data)
      const rowsData = response?.data
      const arrData = []
      rowsData.map(row => (
        arrData.push(createData(row.LabName, row.Capacity, row.SamplesCurrentWeek, row.SamplesLastWeek))
      ))
      setRows(arrData)

    }
    loadInstrumentCapacity()
  },[])
  
  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table aria-label="collapsible table" >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Laboratorio</TableCell>
            <TableCell align="right">Capacidade</TableCell>
            <TableCell align="right">Semana Anterior</TableCell>
            <TableCell align="right">Semana Corrente</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}