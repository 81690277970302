export default {
  headers: [
    // "ID da Amostra",
    "Nome",
    "Apelido",
    "NID",
    "Idade",
    "Sexo",
    "Contacto",
    "Provincia",
    "Distrito",
    "Unidade Sanitária",
    "Data de Colheita",
    "Data de Registo",
    "Data de Análise",
    "Data de Validação",
    // "Resultado",
    // "Resultado (ABS)",
    "Resultado Final",
    "Supressão Viral",
    "Motivo de Teste"
  ]
};